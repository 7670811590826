.service-type-selector {
  width: 100%;
}

@media screen and (max-width: 1139px) {
  .service-type-selector > .col-12 {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-content: center;
  }

  .service-type-selector {
    height: var(--section-title-height);
  }
}