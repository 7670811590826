#tutorial {
  /* variables */
  --button-height: 2rem;
  /* --------- */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--prim-color);
  color: white;
  padding: 5% 5% 2.5% 10%;
}

/* Article */

#tutorial article {
  font-size: var(--font-size-2);
  height: calc(100% - var(--button-height));
  widows: 100%;
  overflow-y: scroll;
  padding-right: 4.5%;
  margin-bottom: 1rem;
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: #ffffff transparent; /* scroll thumb & track */
}

#tutorial article::-webkit-scrollbar {
  width: calc(var(--scrollbar-width) / 2); /* width of the entire scrollbar */
}
#tutorial article::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
#tutorial article::-webkit-scrollbar-thumb {
  background-color: #ffffff; /* color of the scroll thumb */
}

/* Read more Button */

.location-readmore {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  height: var(--button-height);
  background: transparent;
  border: 0;
}

.location-readmore:focus {
  outline: 0;
}

.location-readmore-button {
  height: var(--button-height);
  font-size: var(--font-size-2);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.location-readmore-image {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.location-readmore-image img {
  height: 80%;
  width: auto;
}
