#services-box ul li {
  height: 50%;
  width: 100%;
  float: none;
}

/* Card */

#services-box ul li.card {
  display: inline-block;
  background-color: transparent;
  border: none;
}

/* Body */

#services-box ul li .card-body {
  background-color: #fff;
  overflow: hidden;
  height: 100%;
  padding-top: 15%;
  padding-bottom: calc(20% + 1.25rem + 1.25rem);
  transition: height 0.5s ease, background-color 0.5s ease;
}

#services-box ul li:hover .card-body {
  background-color: var(--prim-color);
}

#services-box ul li .card-body h5 {
  margin: 0;
}

#services-box ul li:hover .card-body h5 {
  color: #ffffff;
}

/* Text */

#services-box ul li .card-body .card-text {
  height: 100%;
  transition: opacity 0.5s ease;
  opacity: 1;
}

#services-box ul li:hover .card-body .card-text {
  opacity: 0;
}

/* Footer */

#services-box ul li .footer {
  font-size: 0.65rem;
  height: 20%;
  bottom: 1.25rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: var(--prim-color);
  border: 0;
  position: relative;
  transform: translateY(-99%);
  transition: height 0.5s ease, background-color 0.5s ease;
  width: 100%;
  padding: 0 1.25rem 4rem 1.25rem;
}

#services-box ul li:hover .footer {
  background-color: var(--prim-color);
  color: #ffffff;
}

/* Themes */

#services-box ul li .service-themes {
  transition: opacity 0.5s ease;
  opacity: 1;
}

#services-box ul li:hover .service-themes {
  opacity: 0;
}

/* Copy Button */

#services-box ul li .copy-button {
  position: absolute;
  top: 0;
  height: 100%;
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  opacity: 0;
  transition: opacity 0.5 ease;
}

#services-box ul li .copy-button > div {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 1.5rem;
  width: 100%;
}

#services-box ul li .copy-button button {
  height: 1.5rem;
  width: 10rem;
  background-color: #ffffff;
  border: 0;
  color: var(--prim-color);
  font-size: var(--font-size-3);
  text-align: center;
}

#services-box ul li .copy-button button:focus {
  outline: none;
}

#services-box ul li:hover .copy-button {
  opacity: 1;
}

#services-box ul li:hover .copy-button .text-transition_inner > div {
  width: 100%;
}

/* Row Padding */

/* first 3 */
#services-box ul li:nth-child(-n + 3) {
  padding-bottom: 1%;
}

/* all but first 3 */
#services-box ul li:nth-child(n + 4) {
  padding-top: 1%;
}
