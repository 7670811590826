#locations-tutorial {
  height: 100%;
  width: 100%;
}

#locations-tutorial:first-child > div {
  width: 100%;
}

.locations-tutorial-item {
  color: #ffffff;
  background-color: var(--prim-color);
  padding: 0;
}

.locations-tutorial-item:last-child {
  margin-bottom: 1em;
}

.locations-tutorial-item .image {
  max-height: 30vh;
  width: 100%;
}

.locations-tutorial-item .image img {
  height: auto;
  width: 100%;
}
