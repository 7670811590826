#tutorial-box {
  height: 100%;
  width: 100%;
  padding: 0;
  overflow: hidden;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

#tutorial-box ul {
  height: 100%;
  width: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
  z-index: 0;
}

#tutorial-step-guider {
  position: absolute;
  height: 60px;
  width: 60px;
  background-color: var(--prim-color);
  display: inline-block;
  z-index: -1;
  transition: top 0.25s ease;
}

#tutorial-box .step {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--prim-color);
  font-size: 1.4rem;
  z-index: 1;
  cursor: pointer;
  transition: color 0.3s ease;
}

#tutorial-box .step:hover {
  color: #ffffff;
  background-color: var(--prim-color-transparent);
}

#tutorial-box .step.active {
  color: white;
}

#tutorial-item-image {
  height: 100%;
  width: 100%;
  padding-right: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

#tutorial-item-image div {
  overflow: hidden;
}

#tutorial-item-image img {
  height: auto;
  width: 100%;
}
