#views-box-vertical {
  position: relative;
  padding: 0;
  height: calc(100vh - var(--header-height) - var(--section-title-height) - var(--tag-selector-height) - 5em);
  width: 100%;
  list-style-type: none;
  overflow-y: scroll;
  scrollbar-width: thin;
  /* "auto" or "thin"  */
  scrollbar-color: var(--prim-color) #ffffff;
  /* scroll thumb & track */
}

#views-box-vertical.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#views-box-vertical::-webkit-scrollbar {
  width: var(--scrollbar-width);
  /* width of the entire scrollbar */
}

#views-box-vertical::-webkit-scrollbar-track {
  background: #ffffff;
  /* color of the tracking area */
}

#views-box-vertical::-webkit-scrollbar-thumb {
  background-color: var(--prim-color);
  /* color of the scroll thumb */
}

#views-box-vertical-scrollbar.inactive {
  background-color: var(--light-bg-color);
}

#views-box-vertical-scrollbar div {
  background-color: #09b0c9 !important;
}

#views-box-vertical li {
  height: 15em;
  width: 100%;
  max-height: 20vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 var(--scrollbar-width) 0 0;
  width: calc(100% - var(--scrollbar-width));
}

#views-box-vertical>li:last-child {
  margin: 0 !important;
}

#views-box-vertical li .card-body {
  padding: 0;
  overflow: hidden;
  height: 100%;
  /*background-position: center;
  background-size: cover;
  background-repeat: no-repeat;*/
  background-color: #ffffff;
  background-size: 90% auto;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

#views-box-vertical li .card-footer {
  position: relative;
  padding: 0 5% 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  background-color: var(--prim-color);
}

#views-box-vertical li .card-footer h5 {
  color: #ffffff;
  font-size: var(--font-size-1);
  /* border-bottom: 1px solid #ffffff; */
}

@media screen and (max-height: 400px) {
  #views-box-vertical li .card-footer h5 {
    margin-bottom: 0;
  }
}

#views-box-vertical li .card-footer p {
  color: #ffffff;
  font-size: var(--font-size-2);
  margin-bottom: 0;
}

#views-box-vertical .card-link {
  position: absolute;
  bottom: 4%;
  right: 5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  height: auto;
}

#views-box-vertical .card-link a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 5rem;
  background-color: #ffffff;
  color: var(--prim-color);
}

#views-box-vertical .card-link a:hover {
  text-decoration: none;
}

/* scrollbar */
#views-box-vertical div div {
  transition: height 0.5s ease;
}

@media screen and (max-width: 600px) {
  #views-box-vertical li .card-footer h5 {
    font-size: var(--font-size-2);
  }

  #views-box-vertical li .card-footer p {
    font-size: var(--font-size-4);
  }
}

@media screen and (max-height: 900px) and (orientation: portrait) {
  #views-box-vertical {
    transform: translateY(-20px);
  }
}

@media screen and (max-height: 900px) and (orientation: landscape) {
  #views-box-vertical {
    transform: translateY(-20px);
  }
}

@media screen and (max-height: 900px) {
  #views-box-vertical {
    transform: translateY(0px);
  }

  #views-box-vertical {
    max-height: calc(100vh - var(--header-height) - var(--section-title-height) - var(--tag-selector-height) - 3.2em);
  }
}

@media screen and (max-height: 400px) {
  #views-box-vertical li {
    margin-bottom: 0.5em !important;
  }

  #views-box-vertical {
    transform: translateY(-5px);
  }

  #views-box-vertical {
    max-height: calc(100vh - var(--header-height) - var(--section-title-height) - var(--tag-selector-height) - 1em);
  }

  #views-box-vertical li .card-footer h5 {
    font-size: var(--font-size-3);
  }

  #views-box-vertical li .card-footer p {
    font-size: var(--font-size-4);
  }
}

@media screen and (max-height: 600px) {
  #views-box-vertical .card-link a {
    width: 3rem;
    font-size: var(--font-size-3);
  }
}

@media screen and (max-width: 400px) {
  #views-box-vertical .card-link a {
    width: 3rem;
    font-size: var(--font-size-3);
  }
}