#services-types {
  position: absolute;
  right: 0;
}

#services-types button {
  font-size: 14px;
  color: var(--font-color-prim);
  margin-left: 0.5rem;
  background-color: transparent;
  border-color: var(--font-color-prim);
  box-shadow: 0;
  border-radius: 0;
}

#services-types button.btn {
  padding: 1px 8px 1px 8px;
}

#services-types button:focus {
  outline: none;
  box-shadow: none;
}

#services-types button.active {
  color: #fff;
  background-color: var(--prim-color);
  border: 0;
}

#service-search {
  width: 100%;
  border-bottom: 2px solid var(--prim-color);
  height: var(--service-search-input-height);
}

#service-search input::placeholder {
  font-size: var(--font-size-1);
}

#service-search .form-control {
  padding: 0;
  border: 0;
  font-size: var(--font-size-1);
  background-color: transparent;
}

#services-tag-title-vertical {
  width: 100%;
  font-size: var(--font-size-2);
}

#service-search .form-control:focus {
  box-shadow: none;
}

.section .row .section-title {
  padding: 0;
}

@media screen and (max-height: 500px) {
  #service-search input::placeholder {
    font-size: var(--font-size-3);
  }

  #service-search .form-control {
    padding: 0;
    font-size: var(--font-size-3);
  }

  #services-tag-title-vertical {
    font-size: var(--font-size-4);
  }
}

@media screen and (max-height: 400px) {
  .services-types {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
