#tag-list-pagination {
  position: relative;
  bottom: 1rem;
  width: 100%;
  height: 25px;
  text-align: center;
  margin: 0;
  font-size: var(--font-size-5);
  opacity: 0.5;
}

#tag-list-pagination span {
  cursor: pointer;
}
