#header {
  position: absolute;
  top: 0;
  z-index: 5;
  height: var(--header-height);
  width: 100%;
  max-width: var(--container-max-width);
  padding: 0 var(--container-padding) 0 var(--container-padding);
  background-color: transparent;
  align-items: flex-end;
}

#nav {
  width: 100%;
  height: var(--nav-height);
  background-color: #ffffff;
  box-shadow: 0 8px 8px 0px rgba(0, 0, 0, 0.5);
}

.logo {
  height: 100%;
  width: 200px;
}

.logo img {
  height: 80%;
  width: auto;
}

.menu-burguer {
  margin-left: auto;
  margin-right: 20px;
  height: 50px;
  width: 50px;
  opacity: 0.5;
  cursor: pointer;
}

.menu-burguer img {
  height: 100%;
  width: auto;
}

#nav-links {
  height: 100%;
  margin: 0;
  list-style-type: none;
  margin-left: auto;
}

#nav-links .link {
  display: flex;
  justify-content: center;
  align-content: center;
}

#nav-links .link a {
  color: var(--font-color-prim);
  font-size: var(--font-size-2);
  font-weight: 300;
  text-align: center;
}

#nav-links li p {
  color: var(--font-color-prim);
  margin: 0;
  cursor: pointer;
  font-size: var(--font-size-2);
  font-weight: 300;
  text-align: center;
}

#nav-links li.submenu {
  position: relative;
}

#nav-links .link a:hover,
#nav-links .link:hover {
  text-decoration: none;
  color: var(--prim-color);
}

#nav-links li.submenu:hover ul,
#external-links-menu:hover {
  display: block;
}

#external-links-menu {
  display: none;
  position: absolute;
  width: 100%;
  padding: 0;
  background-color: #ffffff;
  list-style-type: none;
}

#external-links-menu li {
  height: 3rem;
  color: var(--font-color-prim);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#external-links-menu li:hover {
  color: #ffffff;
  background-color: var(--prim-color);
}

#external-links-menu li a {
  height: 100%;
  color: inherit;
  font-size: var(--font-size-3);
  font-weight: 300;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1500px) {
  #nav-links .link a {
    font-size: var(--font-size-4);
    font-weight: 300;
  }

  #nav-links li p {
    font-size: var(--font-size-4);
    font-weight: 300;
  }

  #external-links-menu li a {
    font-size: var(--font-size-4);
    font-weight: 300;
  }
}

@media screen and (max-width: 1400px) {
  #nav-links .link a {
    font-size: var(--font-size-5);
    font-weight: 300;
  }

  #nav-links li p {
    font-size: var(--font-size-5);
    font-weight: 300;
  }

  #external-links-menu li a {
    font-size: var(--font-size-5);
    font-weight: 300;
  }
}

@media screen and (max-width: 1139px) {
  #nav {
    justify-content: flex-start;
  }

  .logo {
    height: 100%;
    width: 100px;
    margin-left: 1em !important;
  }

  .logo img {
    height: auto;
    width: 100%;
  }

  .logo a {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 400px) {
  .logo {
    width: 80px;
    margin-left: 0.5em !important;
  }
}

@media screen and (max-height: 900px) {
  .menu-burguer {
    height: 40px;
    width: 40px;
  }
}

@media screen and (max-height: 600px) {
  .menu-burguer {
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width: 400px) {
  .menu-burguer {
    height: 30px;
    width: 30px;
  }
}
