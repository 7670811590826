#views-tags-selector {
  /* variables */
  --arrow-width: 1.75rem;
  /* --------- */
  position: relative;
  height: var(--tag-selector-height);
  width: 100%;
  color: #ffffff;
  background-color: var(--prim-color);
  cursor: pointer;
}

#views-tags-selector #active-tag {
  float: left;
  height: 100%;
  width: calc(100% - var(--arrow-width) - 0.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex;
}

#views-tags-selector h4 {
  font-size: var(--font-size-2);
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#views-tags-selector #arrow {
  /*float: right;*/
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex;
  height: 100%;
  width: 1.75rem;
  transition: transform 0.5s ease;
  transform: rotate(0deg);
}

#views-tags-selector #arrow.open {
  transform: rotate(180deg);
}

#views-tags-selector #arrow img {
  height: auto;
  width: 100%;
}

#views-tag-list-vertical {
  position: absolute;
  /*bottom: calc(var(--tag-selector-height) * -1);*/
  top: var(--tag-selector-height);
  height: 0 !important;
  width: 100%;
  z-index: 2;
  overflow: hidden !important;
  background-color: #ffffff;
  transition: height 0.5s ease-in-out;
}

#views-tag-list-vertical.open {
  height: 270px !important;
}

#views-tag-list-vertical ul {
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 0px;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  transition: max-height 0.5s ease-in-out;
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: var(--prim-color) #ffffff; /* scroll thumb & track */
}

#views-tag-list-vertical ul::-webkit-scrollbar {
  width: var(--scrollbar-width); /* width of the entire scrollbar */
}
#views-tag-list-vertical ul::-webkit-scrollbar-track {
  background: #ffffff; /* color of the tracking area */
}
#views-tag-list-vertical ul::-webkit-scrollbar-thumb {
  background-color: var(--prim-color); /* color of the scroll thumb */
}

#views-tags-selector #views-tag-list-vertical ul.open {
  max-height: 270px;
}

#views-tag-list-vertical {
  padding: 0;
  height: auto;
  width: 100%;
  list-style-type: none;
}

#views-tag-list-vertical li {
  width: 100%;
  text-align: start;
  color: #000;
  padding: 15px 20px 15px 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
}

#views-tag-list-vertical li p {
  margin-left: 0px;
  margin-bottom: 0;
  transition: margin-left 0.3s ease;
}

#views-tag-list-vertical li:hover {
  color: #ffffff;
  background-color: var(--prim-color-transparent);
}

#views-tag-list-vertical li.active {
  color: #ffffff;
  background-color: var(--prim-color);
}

#views-selector-scrollbar {
  background-color: #ffffff;
  height: 100%;
  width: 1em;
  top: 0;
  right: 0;
  position: absolute;
}

#views-selector-scrollbar div {
  transition: height 0.5s ease;
  background-color: #09b0c9 !important;
}

@media screen and (max-height: 600px) {
  #views-tag-list-vertical {
    max-height: 45vh;
  }

  #views-tags-selector #views-tag-list-vertical ul.open {
    max-height: 45vh;
  }
}
