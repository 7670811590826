#services-box-vertical {
  position: relative;
  padding: 0;
  height: calc(
    100vh - var(--header-height) - var(--section-title-height) -
      var(--service-search-input-height) - var(--tag-selector-height) - 5em
  );
  width: 100%;
  list-style-type: none;
  overflow-y: scroll;
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: var(--prim-color) #ffffff; /* scroll thumb & track */
}

#services-box-vertical.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#services-box-vertical::-webkit-scrollbar {
  width: var(--scrollbar-width); /* width of the entire scrollbar */
}
#services-box-vertical::-webkit-scrollbar-track {
  background: #ffffff; /* color of the tracking area */
}
#services-box-vertical::-webkit-scrollbar-thumb {
  background-color: var(--prim-color); /* color of the scroll thumb */
}

.service-box-title {
  width: 100%;
  padding: 0;
  align-items: center;
  font-size: 18px;
}

.truncation {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.service-box-themes {
  padding: 0;
  color: var(--prim-color);
  font-size: 0.65rem;
  left: 0;
  width: 100%;
}

.service-box-themes p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (max-width: 598.9px) {
  .service-box-themes p {
    -webkit-line-clamp: 1;
  }
}

@media (max-width: 1000px) {
  .truncation {
    -webkit-line-clamp: 2;
  }
  .temas p {
    -webkit-line-clamp: 1;
  }
}

@media (max-width: 243.9px) {
  .truncation {
    -webkit-line-clamp: 1;
  }
}

/* TODO: FIX THIS */

@media screen and (max-height: 900px) and (orientation: portrait) {
  #services-box-vertical {
    transform: translateY(-20px);
  }
}

@media screen and (max-height: 900px) and (orientation: landscape) {
  #services-box-vertical {
    transform: translateY(-20px);
  }
}

@media screen and (max-height: 900px) {
  #services-box-vertical {
    transform: translateY(0px);
  }
  #services-box-vertical {
    max-height: calc(
      100vh - var(--header-height) - var(--section-title-height) -
        var(--service-search-input-height) - var(--tag-selector-height) -
        var(--input-search-height) - 6em
    );
  }
}

@media screen and (max-height: 400px) {
  #services-box-vertical .row {
    margin-bottom: 0.5em !important;
  }

  #services-box-vertical {
    transform: translateY(-5px);
  }

  #services-box-vertical {
    max-height: calc(
      100vh - var(--header-height) - var(--section-title-height) -
        var(--service-search-input-height) - var(--tag-selector-height) -
        var(--input-search-height) - 1em
    );
  }
}
