#views-box {
  height: 100%;
  max-height: 630px;
  padding: 20px 0 0 0;
  padding-top: 0;
}

#views-box ul {
  height: 100%;
}

#views-right-arrow {
  right: -0.25rem;
  transform: translateX(100%);
}

#views-left-arrow {
  left: 0.25rem;
}

.views-arrow {
  height: 100%;
  width: 3rem;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.views-arrow img {
  width: 1.5rem;
  cursor: pointer;
}
