#views-tag-box {
  height: 100%;
  max-height: 630px;
  background-color: #ffffff;
  padding: 20px 0 30px 0;
  -webkit-box-shadow: 1px 7px 9px -2px rgba(128, 123, 128, 1);
  -moz-box-shadow: 1px 7px 9px -2px rgba(128, 123, 128, 1);
  box-shadow: 1px 7px 9px -2px rgba(128, 123, 128, 1);
}

#views-tag-list {
  padding: 0;
  height: 100%;
  width: 100%;
  list-style-type: none;
}

#views-tag-list li {
  width: 100%;
  text-align: start;
  color: #000;
  padding: 3% 5% 3% 5%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  font-size: var(--font-size-2);
}
/*
@media screen and (max-height: 768px) {
  #views-tag-list li {
    font-size: var(--font-size-3);
  }
}

@media screen and (max-height: 700px) {
  #views-tag-list li {
    font-size: var(--font-size-5);
  }
}*/

#views-tag-list li p {
  margin-left: 0px;
  margin-bottom: 0;
  transition: margin-left 0.3s ease;
}

#views-tag-list li:before {
  content: '';
  width: 0px;
  border-bottom: solid 1px #000;
  position: absolute;
  left: 20px;
  top: 50%;
  z-index: 1;
  transition: width 0.2s ease, border-bottom 0.2s ease;
}

#views-tag-list li:hover {
  color: #ffffff;
  background-color: var(--prim-color-transparent);
}

#views-tag-list li:hover p {
  margin-left: 5px;
}

#views-tag-list li.active {
  color: #ffffff;
  background-color: var(--prim-color);
}

#views-tag-list li.active p {
  margin-left: 20px;
}

#views-tag-list li.active:before {
  width: 10px;
  border-bottom: solid 1px #ffffff;
}
