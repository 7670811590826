#services-box-vertical li.card {
  /* variables */
  --button-height: 1.5rem;
  /* --------- */
  height: auto;
  min-height: 20vh;
  width: calc(100% - var(--scrollbar-width));
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 0;
  margin: 0 var(--scrollbar-width) 0 0;
}

#services-box-vertical>div:last-child {
  margin: 0 !important;
}

/* Card */

#services-box-vertical li.card:focus {
  outline-style: none;
}

/* Button */

.service-box-themes .copy-button {
  display: flex;
  justify-content: center;
  height: var(--button-height);
  transition: height 0.5s ease, opacity 0.5s ease;
}

.service-box-themes .copy-button button {
  background-color: var(--prim-color);
  border: 0;
  width: 5rem;
  color: #ffffff;
  font-size: var(--font-size-3);
}

.service-box-themes .copy-button button:focus {
  outline-style: none;
}

/* Queries */

@media screen and (max-width: 600px) {
  #services-box-vertical li.card .card-footer h5 {
    font-size: var(--font-size-2);
  }

  #services-box-vertical li.card .card-footer p {
    font-size: var(--font-size-4);
  }
}

@media screen and (max-height: 900px) {
  #services-box-vertical li.card-footer h5 {
    font-size: var(--font-size-3);
  }

  #services-box-vertical li.card-footer p {
    font-size: var(--font-size-4);
  }
}