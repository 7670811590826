#views-box ul li {
  height: 50%;
  width: 100%;
  float: none;
}

#views-box ul li.card {
  display: inline-block;
  background-color: transparent;
  border: none;
}

#views-box ul li .card-body {
  background-color: #ffffff;
  padding: 0;
  overflow: hidden;
  height: 100%;
  /*background-position: center;
  background-size: cover;
  background-repeat: no-repeat;*/
  background-size: 90% auto;
  background-repeat: no-repeat;
  background-position: 50% 30%;
  transition: height 0.5s ease, background-color 0.5s ease;
  background-image: url('/images/Logo_NDT_hor_v2.png');
}

#views-box ul li:hover .card-footer,
#views-box ul li.no-image:hover .card-body {
  background-color: var(--prim-color);
}

#views-box ul li .card-footer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 20%;
  width: 100%;
  background-color: transparent !important;
  border: 0;
  position: relative;
  transform: translateY(-99%);
  transition: height 0.5s ease, background-color 0.5s ease;
  padding: 0;
}

#views-box ul li.no-image .card-footer {
  height: 100%;
  justify-content: flex-end;
  overflow: visible;
  background-color: transparent;
}

#views-box ul li .card-footer .content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}

#views-box ul li:hover .card-footer {
  height: 100%;
}

#views-box ul li .card-footer-container {
  height: 92.5%;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  transition: height 0.5s ease, background-color 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}

#views-box ul li:not(.no-image):hover .card-footer-container {
  /*height: 92.5%;*/
  height: 100%;
}

#views-box ul li:hover .card-footer-container {
  background-color: var(--prim-color);
}

#views-box ul li .card-footer h5 {
  color: var(--font-color-sec);
  font-size: var(--font-size-3);
  font-weight: 200;
  text-align: center;
  border-bottom: 1px solid var(--prim-color);
  transition: color 0.5s ease, border-bottom 0.5s ease;
  padding-bottom: 2.5px;
  display: inline;
}

#views-box ul li .view-item-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

#views-box ul li:hover .card-footer h5 {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}

#views-box ul li .card-footer .content div {
  font-size: var(--font-size-3);
  font-weight: 200;
  opacity: 0;
  color: #ffffff;
  transition: opacity 0.5s ease, color 0.5s ease;
}

#views-box ul li.no-image .card-footer .content div {
  opacity: 1;
  color: #000000;
}

#views-box ul li.no-image:hover .card-footer .content div {
  color: #ffffff;
}

#views-box ul li:hover .card-footer .content div {
  opacity: 1;
}

#views-box ul li .card-footer .content p {
  text-align: justify;
  text-justify: inter-word;
  cursor: default;
}

#views-box ul li .card-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1.25rem;
  opacity: 0;
  transition: opacity 0.5s ease;
}

#views-box ul li:hover .card-link {
  opacity: 1;
}

#views-box ul li .card-link a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 4rem;
  background-color: #ffffff;
  color: var(--prim-color);
  font-size: var(--font-size-3);
  font-weight: bolder;
}

#views-box ul li .card-link a:hover {
  text-decoration: none;
}

/* first 3 */
#views-box ul li:nth-child(-n + 3) {
  padding-bottom: 1%;
}

/* all but first 3 */
#views-box ul li:nth-child(n + 4) {
  padding-top: 1%;
}