.section {
  height: 100vh;
  width: 100vw;
  max-width: var(--container-max-width);
  padding: var(--header-height) var(--container-padding) 0
    var(--container-padding);
  overflow: hidden;
  /*display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;*/
}

.section.scroll {
  display: block;
}

.section-title {
  align-self: flex-start;
  color: var(--font-color-sec);
  font-size: var(--font-size-1);
  font-weight: 400;
  margin: 0;
  height: var(--section-title-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-title h2 {
  color: var(--font-color-sec);
  font-size: var(--font-size-title);
  font-weight: 400;
}

.section-title h2 div {
  position: absolute;
  left: 0;
}

.section-container {
  height: calc(100vh - var(--header-height) - var(--section-title-height) - 1%);
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 2% 0;
}

@media screen and (max-width: 1139px) {
  .section-container {
    flex-direction: column;
  }
}

.section-container.scrollable {
  height: calc(100vh - var(--header-height) - var(--section-title-height));
  overflow-x: scroll;
  padding-bottom: var(--mobile-bottom-padding) !important;
}

@media screen and (max-height: 400px) {
  .section-title {
    font-size: var(--font-size-1);
  }
}
