#services-tag-box {
  /* variables */
  --search-height: 38px;
  --search-under-text-height: 24px;
  /* --------- */
  height: 100%;
  max-height: 630px;
  background-color: #fff;
  padding: 20px 0 30px 0;
  -webkit-box-shadow: 1px 7px 9px -2px rgba(128, 123, 128, 1);
  -moz-box-shadow: 1px 7px 9px -2px rgba(128, 123, 128, 1);
  box-shadow: 1px 7px 9px -2px rgba(128, 123, 128, 1);
}

#services-tag-service-box-title {
  font-size: var(--font-size-1);
  padding-top: 15px;
  color: var(--font-color-prim);
}

#service-search {
  border-bottom: 2px solid var(--prim-color);
}

#service-search .btn {
  background-color: transparent;
  padding: 0;
  border: 0;
}

#service-search .icon {
  width: 24px;
  height: 24px;
}

#service-search .btn:focus {
  box-shadow: none;
}

#services-tag-list {
  margin: 0;
  padding: 1rem 0 1rem 0;
  height: calc(100% - var(--search-height) - var(--search-under-text-height));
  width: 100%;
  list-style-type: none;
  padding-left: 0;
}

#services-tag-list li {
  font-size: 0.7rem;
  width: 100%;
  text-align: start;
  color: #000;
  padding: 0px 20px 0px 20px !important;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
}

#services-tag-list li p {
  margin-left: 0px;
  margin-bottom: 0;
  transition: margin-left 0.3s ease;
}

#services-tag-list li:before {
  content: '';
  width: 0px;
  border-bottom: solid 1px #000;
  position: absolute;
  left: 20px;
  top: 50%;
  z-index: 1;
  transition: width 0.2s ease, border-bottom 0.2s ease;
}

#services-tag-list li:hover {
  color: #fff;
  background-color: var(--prim-color-transparent);
}

#services-tag-list li:hover p {
  margin-left: 5px;
}

#services-tag-list li.disable {
  color: var(--prim-color);
  border: 2px solid;
  border-color: var(--prim-color);
}

#services-tag-list li.active {
  color: #fff;
  background-color: var(--prim-color);
}

#services-tag-list li.active p {
  margin-left: 20px;
}

#services-tag-list li.active:before {
  width: 10px;
  border-bottom: solid 1px #fff;
}
