@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}

.view-image.views-loading-bg-gradient {
  height: 70%;
  border-radius: 15px;
  margin: 5%;
}

.card-text.views-loading-bg-gradient {
  height: 40px !important;
  margin-left: 5%;
  width: 50% !important;
  border-radius: 15px;
}

.footer.views-loading-bg-gradient {
  height: 20%;
  margin-left: 5%;
  width: 90% !important;
  border-radius: 15px;
  transform: translateY(-99%);
}

.views-loading-bg-gradient {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 100px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
