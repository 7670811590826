:root {
  /* Colors */
  --prim-color: #09b0c9;
  --prim-color-transparent: rgba(9, 176, 201, 0.5);
  --light-bg-color: #f6f6f6;
  --font-color-prim: #868686;
  --font-color-sec: #363636;
  /* Font Sizes */
  --font-size-title: 1.5rem;
  --font-size-1: 1rem;
  --font-size-2: 0.87rem;
  --font-size-3: 0.75rem;
  --font-size-4: 0.7rem;
  --font-size-5: 0.625rem;
  /* Header */
  --header-height: 150px;
  --nav-height: 100px;
  /* Container */
  --container-padding: 7.5%;
  --container-max-width: 1920px;
  --mobile-bottom-padding: 3em;
  /* Page Title */
  --section-title-height: 6.666rem;
  /* Page: Viewers & Services */
  --tag-selector-height: 3rem;
  --scrollbar-width: 12px;
  /* Page: Services */
  --input-search-height: 2.5em;
  --service-search-input-height: 38px;
}

@media screen and (max-height: 1100px) and (orientation: portrait) {
  :root {
    /* Page Title */
    --section-title-height: 6.666rem;
  }
}

@media screen and (max-height: 900px) {
  :root {
    /* Header */
    --header-height: 100px;
    --nav-height: 80px;
    /* Page Title */
    --section-title-height: 6.666rem;
    /* Page: Viewers & Services */
    --tag-selector-height: 3rem;
  }
}

@media screen and (max-height: 900px) and (orientation: portrait) {
  :root {
    /* Header */
    --header-height: 75px;
    --nav-height: 60px;
    /* Page Title */
    --section-title-height: 5rem;
    /* Page: Viewers */
    --tag-selector-height: 3rem;
  }
}

@media screen and (max-height: 900px) and (orientation: landscape) {
  :root {
    /* Header */
    --header-height: 100px;
    --nav-height: 75px;
    /* Page Title */
    --section-title-height: 5rem;
    /* Page: Viewers */
    --tag-selector-height: 3rem;
  }
}

@media screen and (max-height: 600px) {
  :root {
    /* Header */
    --header-height: 60px;
    --nav-height: 50px;
    /* Page Title */
    --section-title-height: 5rem;
    /* Page: Viewers */
    --tag-selector-height: 2rem;
  }
}

@media screen and (max-height: 400px) {
  :root {
    /* Page Title */
    --section-title-height: 2rem;
    /* Page: Viewers */
    --tag-selector-height: 2rem;
    /* Page: Services */
    --service-search-input-height: 30px;
  }
}

@font-face {
  font-family: 'fieldwork';
  src: local('fieldwork'),
    url(/fonts/Fieldwork-GeoRegular.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'fieldwork';
  src: local('fieldwork'), url(/fonts/Fieldwork-GeoLight.otf) format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'fieldwork';
  src: local('fieldwork'), url(/fonts/Fieldwork-GeoThin.otf) format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'fieldwork';
  src: local('fieldwork'), url(/fonts/Fieldwork-GeoHair.otf) format('opentype');
  font-weight: 100;
}

body {
  margin: 0;
  font-family: 'fieldwork', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.truncation {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.truncate-text {
  overflow: visible !important;
}

.fade-in {
  animation-delay: 250ms;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
