#homepage {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: var(--container-max-width);
  padding: 0 var(--container-padding) 0 var(--container-padding);
}

#homepage-info {
  color: white;
  z-index: 1;
}

#homepage-carousel,
.carousel-inner,
.carousel-item {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.carousel-item {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media screen and (max-width: 1139px) {
  #homepage-info h1 {
    font-size: 2rem;
  }
  #homepage-info h2 {
    font-size: 1.5rem;
  }
}
