#sidenav {
  /* Variables */
  --sidenav-padding-right: 1em;
  /* --------- */
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 0px;
  background-color: #ffffff;
  transition: width 0.5s ease, box-shadow 0.5s ease;
  box-shadow: 0 2em 2em 1em rgba(0, 0, 0, 0);
  pointer-events: none;
}

#sidenav.open {
  width: 300px;
  box-shadow: 0 2em 2em 1em rgba(0, 0, 0, 0.5);
  pointer-events: all;
}

#sidenav-cross {
  position: absolute;
  top: calc(var(--header-height) - var(--nav-height));
  right: 0;
  height: var(--nav-height);
  width: var(--nav-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  margin-right: var(--sidenav-padding-right);
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

#sidenav.open #sidenav-cross {
  opacity: 1;
  transform: translateX(0%);
}

#sidenav-cross img {
  height: 40%;
  width: 40%;
}

#sidenav-links {
  position: absolute;
  top: calc(var(--header-height) + 7.5%);
  left: 0;
  width: 100%;
  opacity: 0;
  animation: fadeOut 200ms;
}

@media screen and (max-height: 400px) {
  #sidenav-links {
    position: absolute;
    top: calc(var(--header-height));
  }
}

#sidenav.open #sidenav-links {
  animation: fadeIn 750ms;
  opacity: 1;
}

#sidenav-links > :first-child {
  list-style-type: none;
  margin: 0 var(--sidenav-padding-right) 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

#sidenav-links .sidenav-link {
  text-align: end;
  color: gray;
  margin: 0;
}

#sidenav-links ul li a:hover {
  text-decoration: none;
}

.sidenav-submenu-link {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.sidenav-submenu-link span {
  height: 1em;
  width: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin: 0;
}

.sidenav-submenu-link img {
  height: 1em;
  width: 1em;
  transform: rotate(0deg);
  transition: transform 0.4s ease-in-out;
}

.sidenav-item.open .sidenav-submenu-link img {
  max-height: 100px;
  transform: rotate(180deg);
}

.sidenav-submenu-list {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.sidenav-item.open .sidenav-submenu-list {
  max-height: 100px;
}

.sidenav-submenu-list li a {
  font-size: var(--font-size-3);
  color: black;
}

#language-selector-sidenav {
  float: right;
  margin: 0 var(--sidenav-padding-right) 0 0;
}

#language-selector-sidenav button {
  background-color: transparent;
  border: 0;
  font-size: var(--font-size-title);
  color: gray;
}

#language-selector-sidenav span {
  height: 100%;
  font-size: var(--font-size-title);
  color: black;
}

#language-selector-sidenav button.active {
  color: black;
}

#language-selector-sidenav button:focus {
  outline: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
