#language-selector {
  list-style-type: none;
  margin-bottom: 0;
  padding: 0;
}

#language-selector li {
  cursor: pointer;
  display: flex;
  align-items: center;
  max-height: 0;
  opacity: 0;
  animation: grow 1s ease-in-out forwards;
  -webkit-animation: grow 0.3s ease-in-out forwards;
}

@-webkit-keyframes grow {
  to {
    max-height: 20px;
    opacity: 1;
  }
}
@keyframes grow {
  to {
    max-height: 20px;
    opacity: 1;
  }
}
